import * as React from 'react'
import Masonry from 'react-masonry-css'

//prettier-ignore
import { HomeGrid, HomeStyled } from './Home.style'

export const Home = () => {
  return (
    <HomeStyled>
      <img className="right" alt="right" src="/right.svg" />
      <img className="left" alt="left" src="/left.svg" />
      <img className="main" alt="main" src="/main.svg" />

      <Masonry breakpointCols={3} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
        <a href="/images/16.jpg" target="_blank">
          <img alt="art" src="/images/16.jpg" />
        </a>
        <a href="/images/17.jpg" target="_blank">
          <img alt="art" src="/images/17.jpg" />
        </a>
        <a href="/images/18.jpg" target="_blank">
          <img alt="art" src="/images/18.jpg" />
        </a>
        <a href="/images/19.jpg" target="_blank">
          <img alt="art" src="/images/19.jpg" />
        </a>
        <a href="/images/20.jpg" target="_blank">
          <img alt="art" src="/images/20.jpg" />
        </a>
        <a href="/images/1.jpg" target="_blank">
          <img alt="art" src="/images/1.jpg" />
        </a>
        <a href="/images/2.jpg" target="_blank">
          <img alt="art" src="/images/2.jpg" />
        </a>
        <a href="/images/3.jpg" target="_blank">
          <img alt="art" src="/images/3.jpg" />
        </a>
        <a href="/images/4.jpg" target="_blank">
          <img alt="art" src="/images/4.jpg" />
        </a>
        <a href="/images/5.jpg" target="_blank">
          <img alt="art" src="/images/5.jpg" />
        </a>
        <a href="/images/6.jpg" target="_blank">
          <img alt="art" src="/images/6.jpg" />
        </a>
        <a href="/images/7.jpg" target="_blank">
          <img alt="art" src="/images/7.jpg" />
        </a>
        <a href="/images/8.jpg" target="_blank">
          <img alt="art" src="/images/8.jpg" />
        </a>
        <a href="/images/9.jpg" target="_blank">
          <img alt="art" src="/images/9.jpg" />
        </a>
        <a href="/images/10.jpg" target="_blank">
          <img alt="art" src="/images/10.jpg" />
        </a>
        <a href="/images/11.jpg" target="_blank">
          <img alt="art" src="/images/11.jpg" />
        </a>
        <a href="/images/12.jpg" target="_blank">
          <img alt="art" src="/images/12.jpg" />
        </a>
        <a href="/images/15.jpg" target="_blank">
          <img alt="art" src="/images/15.jpg" />
        </a>
        <a href="/images/14.jpg" target="_blank">
          <img alt="art" src="/images/14.jpg" />
        </a>
        <a href="/images/22.jpg" target="_blank">
          <img alt="art" src="/images/22.jpg" />
        </a>
        <a href="/images/21.png" target="_blank">
          <img alt="art" src="/images/21.png" />
        </a>
        <a href="/images/23.jpg" target="_blank">
          <img alt="art" src="/images/23.jpg" />
        </a>
        <a href="/images/24.jpg" target="_blank">
          <img alt="art" src="/images/24.jpg" />
        </a>
        <a href="/images/25.jpg" target="_blank">
          <img alt="art" src="/images/25.jpg" />
        </a>
        <a href="/images/26.jpg" target="_blank">
          <img alt="art" src="/images/26.jpg" />
        </a>
         <a href="/images/27.jpg" target="_blank">
          <img alt="art" src="/images/27.jpg" />
        </a>
        <a href="/images/28.jpg" target="_blank">
          <img alt="art" src="/images/28.jpg" />
        </a>
         <a href="/images/29.jpg" target="_blank">
          <img alt="art" src="/images/29.jpg" />
        </a>
         <a href="/images/30.jpg" target="_blank">
          <img alt="art" src="/images/30.jpg" />
        </a>
     </Masonry>
    </HomeStyled>
  )
}

Home.propTypes = {}

Home.defaultProps = {}
